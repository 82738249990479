/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
:root {
    --header-logo-width: 194px;
    --header-logo-height: 24px;

    --header-height: 60px;
    --header-nav-height: 60px;
    --header-additions-height: 50px;
    --mobile-header-additions-height: 75px;

    --top-container-height: 37px;
    --middle-container-height: 93px;
    --bottom-container-height: 61px;

    @include mobile {
        --header-height: calc(var(--header-nav-height) + var(--mobile-header-additions-height) + var(--offline-notice-height) + var(--demo-notice-height));
        --header-total-height: var(--header-height);
    }
    @include desktop {
        --header-top-menu-height: 32px;
        --header-height: calc(var(--top-container-height) + var(--middle-container-height) + var(--bottom-container-height) + var(--offline-notice-height) + var(--demo-notice-height));
        --header-total-height: var(--header-height);
    }
}

#html-body {
    background: #{$default-background};
}

main {
    background: #{$default-background};
}

.Header {
    max-width: 100%;
    color: white;
    border: 0;
    &-TopContainer {
        background: #004C9B;
        max-width: 100%;
        height: 37px;
        @include mobile {
            height: 25px;
            overflow: hidden;
        }
    }
    &-Top {
        margin: 0 auto;
        max-width: var(--content-wrapper-width);
        background: #004C9B;
        padding-top: 0;
        p {
            font-size: 16px;
            color: $white;
            font-family:'Muli';
            margin-bottom: 0;
            line-height: 37px;
        }
        @include mobile { 
            p {
                font-size: 12px;
                line-height: 25px;                
            }
        }
    }

    &-MiddleContainer {
        max-width: 100%;
        background: repeating-linear-gradient(
            to right,
            #004479,
            #004479 2px,
            #004c9b 2px,
            #004c9b 4px
        );
        height: 93px;
        padding: 15px 30px 0;
        @media (min-width: 1161px)
        and (max-width: 1439px) {
            padding: 15px 15px 0;
        }
        @include ultra-narrow-desktop {
            padding: 15px 10px 0;
        }
        // overflow: hidden;
        @include mobile {
            height: 65px;
            padding: 13px 14px 0;
        }
    }
    &-Middle {
        @include desktop {
            min-height: 59px;
        }
        
        margin: 0 auto;
        max-width: var(--content-wrapper-width);
        /*background: repeating-linear-gradient(
            to right,
            #004479,
            #004479 2px,
            #004c9b 2px,
            #004c9b 4px
        );*/
        display: flex;
        padding-top: 0;
        &-Left {
            width: 10%;
            @include ultra-narrow-desktop {
                width: 15%;
            }
            margin-right: 0;
            max-height: 59px;
            a {
                .Image {
                    &-Image {
                        object-position: unset;
                        max-width: 89px;
                        width: 100%;
                    }
                }
            }
            @include mobile {
                width: 14%;
                margin-top: 3px;
                margin-right: 0;
                padding-right: 16px;
                a {
                    opacity: 1;
                    .Image {
                        &-Image {
                            object-position: unset;
                            max-width: 49px;
                            width: 100%;
                        }
                    }
                }
                display: block;
                padding: 0;
            }
        }
        &-Center {
            width: 50%;
            @include ultra-narrow-desktop {
                width:29%;
            }
            clear: both;
            @include mobile {
                display: none;
            }
            &-TitleContainer {
                &-MainTitle {
                    @include mobile {
                        margin-top: 3px;
                    }
                    div {
                        p {
                            margin: 0;
                            img {
                                width: 100%;
                                max-width: 320px;
                                vertical-align: bottom;
                                height: auto;
                            }
                            @include mobile {
                                font-size: 20px;
                                padding-left: 10px;
                                img {
                                    width: 95%;
                                    height: auto;
                                    margin-top: 5px;
                                    max-width: 200px;
                                }
                            }
                        }
                    }
                }
                &-SubTitle {
                    width: 100%;
                    max-width: 320px;
                    text-align: center;
                    div {
                        p {
                            color: #FFFFFF;
                            font-size: 11px;
                            font-style: italic;
                            margin-bottom: 0;
                            line-height: 20px;
                            @include mobile {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
        &-Right {
            width: 44%;
            @include ultra-narrow-desktop {
                width: 56%;
            }
            @include mobile {
                width: 100%;
                display: flex;
                margin-top: 3px;
            }
            &-Top {
                width: 100%;
                display: -webkit-flex;
                display: flex;
                -webkit-justify-content: flex-end;
                justify-content: flex-end;
                max-height: 25px;
                &-Container {
                    display: flex;
                    div {
                        p {
                            color:#FFFFFF;
                            font-size: 12px;
                            line-height: 15px;
                            margin-bottom: 11px;
                            
                        }
                    }
                    &-ContactPhone {
                        margin-right: 20px;
                        @include ultra-narrow-desktop {
                            //margin-right: 15px;
                        }
                    }
                    &-ContactEmail {
                        margin-right: 20px;
                        @include ultra-narrow-desktop {
                            //margin-right: 15px;
                        }
                    }
                    @include mobile {
                        display: none;
                    }
                }
                .Header-TopMenu {
                    padding: 0px;
                    display: block;
                    margin: 0;
                    margin-right: 0;
                    @include ultra-narrow-desktop {
                        margin-right: 0;
                        .Header-Switcher {
                            .StoreSwitcher {
                                margin-left: 0;
                            }
                        }
                    }
                    @include mobile {
                        display: flex;
                        width: 100%;
                    }
                }
                @include mobile {
                    order: 1;
                    flex-basis: 16%;
                    height: 34px;
                    max-height: unset;
                }
            }
            &-Bottom {
                width: 100%;
                text-align: right;
                .SearchField-Wrapper {
                    max-width: 500px;
                    padding-left: 20px;
                    margin-left: auto;
                    margin-right: 0;
                    @include ultra-narrow-desktop {
                        margin-right: 0;
                    }
                    @include mobile {
                        padding: 0;
                        margin: 0;
                        max-width: unset;
                    }
                }
                .SearchField {
                    width: 100%;
                    margin: 0;
                    padding: 0;
                    &-Wrapper {
                        .SearchField-SearchInnerWrapper {
                            .SearchField-Input {
                                border-radius: 8px;
                                padding: 9px 19px;
                                font-size: 13px;
                                min-height: 39px;
                                color: #9a9a9a;
                                max-width: 375px;
                                @include mobile {
                                    max-width: unset;
                                }
                            }
                        }
                    }
                    &-SearchIcon {
                        top: calc(50% - 11px);
                        .SearchIcon {
                            fill: #9a9a9a;
                        }
                        
                    }
                    &-CloseIcon {
                        right: 5px;
                    }
                    #AdjustFontFamily {
                        .SearchField {
                            &-SearchInnerWrapper {
                                #search-field {
                                    font-family: sans-serif;
                                }
                            }
                        }
                    }
                }
                @include mobile {
                    margin:  0;
                    .SearchField {
                        display: block;
                        &-Wrapper {
                            .SearchField-SearchInnerWrapper {
                                .SearchField-Input {
                                    border-radius: 8px;
                                    padding: 0 5px;
                                    font-size: 13px;
                                    min-height: 34px;
                                    color: #9a9a9a;
                                    line-height: 32px;
                                }
                            }
                        }
                        &-SearchIcon {
                            right: 5px;
                            top: calc(50% - 9px);
                            .SearchIcon {
                                fill: #9a9a9a;
                                height: 22px;
                                width: 22px;
                            }
                        }
                    }
                }
                @media (max-width: 500px) {
                    padding: 0 16px;
                }
            }
        }
    }

    &-Welcome {
        color: $black;
    }

    &-BottomContainer {
        max-width: 100%;
        background: #F9E4C5;
        height: 61px;
        @include mobile {
            // height: 49px;
            height: auto;
            background: #{$default-background};
        }
    }
    &-Bottom {
        margin: 0 auto;
        max-width: var(--content-wrapper-width);
        &-Nav {
            /*margin-top: 18px;*/
            position: absolute;
            right: 0;
            top: 0;
            width: auto;
            display: flex; 
            margin-right: 30px;
            padding-top: 17px;
            padding-right: 30px;
            @include ultra-narrow-desktop {
                padding-right: 0;
            }
            @include mobile {
                position: relative;
                margin-left: 15px;
                margin-right: 15px;
                padding: 0;
                width: auto;
                min-height: 49px;
                h1.Header-Title {
                    font-family: $font-pompiere;
                    position: absolute;
                    color: $black;
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 35px;
                    height: 35px;
                    text-transform: uppercase;
                    padding: 0;
                    margin: 6px auto;
                    max-width: 85%;
                    padding-left: 0;
                }
                .Header-Button {
                    padding-top: 12px;
                    @include mobile {
                        .BackIcon {
                            margin-left: 0;
                        }
                    }
                }
            }
            @media (max-width: 1100px) {
                width: auto;
                min-width: 110px;
                margin-right: 18px;
                margin-left: 12px;
                .Header-MyAccount-Label {
                    display: none;
                }
                .Header-Button-MiniCartLabel {
                    display: none;
                }
                .Header-MyAccountWrapper {
                    .UserIcon {
                        margin-top: 2px;
                    }
                }
            }
        }
    }

    &-Switcher {
        @include mobile {
            height: 100%;
            width: 100%;
        }
        .StoreSwitcher {
            @include mobile {
                margin: 0 auto;
                border-bottom: none;
            }
            .StoreSwitcher-Title  {
                color: #ffffff;
                @include mobile {
                    font-size: 1.8rem;
                }
                .ChevronIcon {
                    color: #ffffff;
                }
            }
        }
    }

    &-MyAccountContainer {
        .Overlay {
            color: $black;
        }
        .Header-MyAccount {
            &Wrapper:hover {
                .Header-MyAccount-Label {
                    color: var(--primary-base-color);
                }
                .UserIcon {
                    fill: var(--primary-base-color);
                }
            }
            height: unset;
            &-Label {
                font-size: 14px;
                color: $black;
                line-height: 20px;
                padding-right: 10px;
                text-transform: uppercase;
                font-weight: 400;
                vertical-align: super;
                cursor: pointer;
            }
        }
    }

    &-Button_type_minicart {
        width: auto;
        .Header-MinicartButtonWrapper:hover {
            .Header-Button-MiniCartLabel {
                color: var(--primary-base-color);
            }
            .CartIcon {
                fill: var(--primary-base-color);
            }
        }
        .Header-Button-MiniCartLabel {
            font-size: 14px;
                color: $black;
                line-height: 20px;
                padding-right: 10px;
                text-transform: uppercase;
                font-weight: 400;
                vertical-align: super;
                cursor: pointer;
        }
        @include mobile {
            margin-bottom: 3px;
        }
    }

    &-CompareButtonWrapper {
        display: none;
    }

    @include mobile {
        &-Wrapper_isHomePage {
            margin-bottom: 90px;
            .Header-Bottom {
                &-Nav {
                    min-height:unset;
                }
            } 
        }
        &-Wrapper_name_menu {
            margin-bottom: 90px;
            .Header-Bottom {
                &-Nav {
                    min-height:unset;
                }
            } 
        }
    }

}

@include mobile{
    .Header_isCheckout{
    position: static;
}
    .Header-Wrapper{
    margin-bottom: 0;
}
}

.Logo {
    background: none;

}


/**
* Menu basic, need to move ASAP...
*/
.Menu {
    &-MenuWrapper {
        background: #F9E4C5;
        @include mobile {
            min-height: calc(100vh - 139px);
        }
        @include desktop {
            .Menu-Item:not(:first-of-type) {
                margin-left: 18px;
            }
        }
    }
    &-MainCategories {
        margin: 0;
        margin-left: 0;
        @media (min-width: 810px)
        and (max-width: 1439px) {
            margin-left: 10px;
        }
        @media (min-width: 810px)
        and (max-width: 1100px) {
            max-width: calc(100% - 130px);
            overflow: hidden;
        }
    }
}

#rapelli_newsletter_modal {
    * {
        margin: revert;
    }
    ._rap_widget_nl_adv {
        font-size: 15px;
    }
    ._rap_widget_nl_h5 {
        font-size: 26px;
    }
    #rapelli_newsletter_modal_form {
        font-size: 15px;
        ._rap_widget_nl_cb {
            vertical-align: sub;
            input {
                padding: 0;
                min-width: 13px;
                min-height: 13px;
                appearance: auto;
                vertical-align: bottom;
                margin-left: 0px;
            }
        }
        ._rap_widget_nl_btn {
            input {
                display: block;
                width: 200px;
                background: #005ca8;
                border-radius: 6px;
                position: relative;
                color: #FFF;
                text-decoration: none;
                border: 0px;
                font-size: 15px;
                line-height: 1;
                padding: 12px;
                font-weight: 700;
                cursor: pointer;
            }
        }
        input[type=email] {
            padding: 8px 12px;
        }
    }
    h3 {
        text-transform: none;
    }
}

body[website="b2b"] {
 
    .Header {
        color: #000;
        &-TopContainer {
            background: #653819;
        }
        &-Top {
            background: inherit;
        }
        &-MiddleContainer {
            background: repeating-linear-gradient(to right, #004479, #004479 2px, #653819 2px, #653819 4px);
            background-image: url("../../../public/images/RAPELLI_background_texture_header.png");
        }
        &-Middle-Left {
            width: 25%;
            padding-left: 30px;
            margin-top:10px;

            a {
                .Image-Image {
                    max-width: unset;
                    object-position: left;
                }
            }
        }
        &-Middle-Center {
            width: 35%;
        }
        &-BottomContainer {
            background: #EFD19F;
            .Menu-MenuWrapper {
                background: #EFD19F;
            }
        }
        &-Switcher {
            .StoreSwitcher {
                .StoreSwitcher-Title {
                    color: #000;
                }
                &-DownIcon {
                    width: 11px;
                    height: 11px;
                    fill: #000;
                    margin: 2px 0 0 2px;
                }
            }
        }
        
    }

    .CmsPage {
        .pagebuilder-slide-wrapper {
            .pagebuilder-slide-button {
                background: #653819;
                border-color: #653819;
            }
        }

    }
}